import { default as _91slug_93CBZ3Xy7WeQMeta } from "/home/ubuntu/deployments/2025-04-10-1013/rtg-monorepo/src/layers/web/pages/tourleader/[id]/[slug].vue?macro=true";
import { default as _91slug_93IHtZqWH6LBMeta } from "/home/ubuntu/deployments/2025-04-10-1013/rtg-monorepo/src/layers/web/pages/titinerary/[slug].vue?macro=true";
import { default as _91name_93DKqlzT6carMeta } from "/home/ubuntu/deployments/2025-04-10-1013/rtg-monorepo/src/layers/web/pages/shipinfo/[name].vue?macro=true";
import { default as _91paxid_93Ipem2yna6jMeta } from "/home/ubuntu/deployments/2025-04-10-1013/rtg-monorepo/src/applications/rolfsbuss-web/pages/satisfactionstatus/[triptypeid]/[paxid].vue?macro=true";
import { default as _91id_93Ks8SVOq1xyMeta } from "/home/ubuntu/deployments/2025-04-10-1013/rtg-monorepo/src/layers/web/pages/payment/[id].vue?macro=true";
import { default as _91id_93jzCoUS6ExyMeta } from "/home/ubuntu/deployments/2025-04-10-1013/rtg-monorepo/src/layers/web/pages/omdome/[id].vue?macro=true";
import { default as _91tab_93LPK9bBaKvAMeta } from "/home/ubuntu/deployments/2025-04-10-1013/rtg-monorepo/src/layers/web/pages/mytrip/[tab].vue?macro=true";
import { default as _91tab_93HXQXzXURObMeta } from "/home/ubuntu/deployments/2025-04-10-1013/rtg-monorepo/src/applications/rolfsbuss-web/pages/info/lectures/[tab].vue?macro=true";
import { default as _91tab_93FrQYuzzQJsMeta } from "/home/ubuntu/deployments/2025-04-10-1013/rtg-monorepo/src/applications/rolfsbuss-web/pages/info/connections/[tab].vue?macro=true";
import { default as _91tab_93zYDKZYWJZkMeta } from "/home/ubuntu/deployments/2025-04-10-1013/rtg-monorepo/src/applications/rolfsbuss-web/pages/info/about/[tab].vue?macro=true";
import { default as _91slug_93n4njValg2HMeta } from "/home/ubuntu/deployments/2025-04-10-1013/rtg-monorepo/src/layers/web/pages/info/[slug].vue?macro=true";
import { default as _91id_93gxORWoT6nBMeta } from "/home/ubuntu/deployments/2025-04-10-1013/rtg-monorepo/src/layers/web/pages/fn/[id].vue?macro=true";
import { default as _91tab_93o7CuulPASbMeta } from "/home/ubuntu/deployments/2025-04-10-1013/rtg-monorepo/src/applications/rolfsbuss-web/pages/destination/[slug]/[tab].vue?macro=true";
import { default as _91slug_93tACaIiZR9iMeta } from "/home/ubuntu/deployments/2025-04-10-1013/rtg-monorepo/src/layers/web/pages/competition/[slug].vue?macro=true";
import { default as _91slug_93MAUuOgOkYzMeta } from "/home/ubuntu/deployments/2025-04-10-1013/rtg-monorepo/src/applications/rolfsbuss-web/pages/category/[slug].vue?macro=true";
import { default as _91ocr_930j20vCaovKMeta } from "/home/ubuntu/deployments/2025-04-10-1013/rtg-monorepo/src/layers/web/pages/bookingfaq/[ocr].vue?macro=true";
import { default as _91ocr_93YGj6Qs0DO8Meta } from "/home/ubuntu/deployments/2025-04-10-1013/rtg-monorepo/src/layers/web/pages/booking-finish/[ocr].vue?macro=true";
import { default as _91id_93HgArG2HtpFMeta } from "/home/ubuntu/deployments/2025-04-10-1013/rtg-monorepo/src/layers/web/pages/booking-confirmation/[id].vue?macro=true";
import { default as _91tripid_932x2eP5IiZnMeta } from "/home/ubuntu/deployments/2025-04-10-1013/rtg-monorepo/src/applications/rolfsbuss-web/pages/bookflight/[tripid].vue?macro=true";
import { default as _91step_93gHdcY1F8AbMeta } from "/home/ubuntu/deployments/2025-04-10-1013/rtg-monorepo/src/layers/web/pages/book/[tripid]/[step].vue?macro=true";
import { default as _91slug_939MbWUNlLqVMeta } from "/home/ubuntu/deployments/2025-04-10-1013/rtg-monorepo/src/applications/rolfsbuss-web/pages/auth/[slug].vue?macro=true";
import { default as _91jwt_93YJNPmUvmYfMeta } from "/home/ubuntu/deployments/2025-04-10-1013/rtg-monorepo/src/layers/web/pages/app/l/[jwt].vue?macro=true";
import { default as _91tab_93tbRlCgrLrLMeta } from "/home/ubuntu/deployments/2025-04-10-1013/rtg-monorepo/src/layers/web/pages/[triptype]/[slug]/[tab].vue?macro=true";
import { default as indexE3R0oS78AGMeta } from "/home/ubuntu/deployments/2025-04-10-1013/rtg-monorepo/src/layers/web/pages/app/index.vue?macro=true";
import { default as callbackpj6wFPm8xEMeta } from "/home/ubuntu/deployments/2025-04-10-1013/rtg-monorepo/src/applications/rolfsbuss-web/pages/auth/callback.vue?macro=true";
import { default as blackweek8NPW8PoOFmMeta } from "/home/ubuntu/deployments/2025-04-10-1013/rtg-monorepo/src/applications/rolfsbuss-web/pages/blackweek.vue?macro=true";
import { default as _91slug_93UvWq7F9BkcMeta } from "/home/ubuntu/deployments/2025-04-10-1013/rtg-monorepo/src/applications/rolfsbuss-web/pages/blog/[slug].vue?macro=true";
import { default as index9oPACTwomdMeta } from "/home/ubuntu/deployments/2025-04-10-1013/rtg-monorepo/src/applications/rolfsbuss-web/pages/blog/index.vue?macro=true";
import { default as bookcallXGsAekzBnnMeta } from "/home/ubuntu/deployments/2025-04-10-1013/rtg-monorepo/src/applications/rolfsbuss-web/pages/bookcall.vue?macro=true";
import { default as byebyeie1carv42YYgMeta } from "/home/ubuntu/deployments/2025-04-10-1013/rtg-monorepo/src/layers/web/pages/byebyeie.vue?macro=true";
import { default as indexwgoBYZa0r0Meta } from "/home/ubuntu/deployments/2025-04-10-1013/rtg-monorepo/src/applications/rolfsbuss-web/pages/calendar/index.vue?macro=true";
import { default as lastminuteHJwB0UlTHbMeta } from "/home/ubuntu/deployments/2025-04-10-1013/rtg-monorepo/src/applications/rolfsbuss-web/pages/calendar/lastminute.vue?macro=true";
import { default as _91name_93Zi0lDLmzGeMeta } from "/home/ubuntu/deployments/2025-04-10-1013/rtg-monorepo/src/applications/rolfsbuss-web/pages/campaign/[name].vue?macro=true";
import { default as indexnBkg1pzwDSMeta } from "/home/ubuntu/deployments/2025-04-10-1013/rtg-monorepo/src/applications/rolfsbuss-web/pages/campaign/index.vue?macro=true";
import { default as catalogueO8akbl58agMeta } from "/home/ubuntu/deployments/2025-04-10-1013/rtg-monorepo/src/applications/rolfsbuss-web/pages/catalogue.vue?macro=true";
import { default as complaintZ0M5siPs5cMeta } from "/home/ubuntu/deployments/2025-04-10-1013/rtg-monorepo/src/layers/web/pages/complaint.vue?macro=true";
import { default as dinbokning_45aktuellpM8Orv69YJMeta } from "/home/ubuntu/deployments/2025-04-10-1013/rtg-monorepo/src/layers/web/pages/dinbokning-aktuell.vue?macro=true";
import { default as _91slug_93TbDnKxeW8CMeta } from "/home/ubuntu/deployments/2025-04-10-1013/rtg-monorepo/src/applications/rolfsbuss-web/pages/event/[slug].vue?macro=true";
import { default as indexrijEA8xkoFMeta } from "/home/ubuntu/deployments/2025-04-10-1013/rtg-monorepo/src/applications/rolfsbuss-web/pages/event/index.vue?macro=true";
import { default as faqbimSyvIHltMeta } from "/home/ubuntu/deployments/2025-04-10-1013/rtg-monorepo/src/layers/web/pages/faq.vue?macro=true";
import { default as _91id_93oM32CnIxGqMeta } from "/home/ubuntu/deployments/2025-04-10-1013/rtg-monorepo/src/layers/web/pages/giftcard/[id].vue?macro=true";
import { default as indexLu5VMsQzAMMeta } from "/home/ubuntu/deployments/2025-04-10-1013/rtg-monorepo/src/layers/web/pages/giftcard/index.vue?macro=true";
import { default as _91name_93ZxO56fZlAQMeta } from "/home/ubuntu/deployments/2025-04-10-1013/rtg-monorepo/src/layers/web/pages/hotels/[name].vue?macro=true";
import { default as indexd00WFWzpviMeta } from "/home/ubuntu/deployments/2025-04-10-1013/rtg-monorepo/src/layers/web/pages/hotels/index.vue?macro=true";
import { default as index95zRhBQEQeMeta } from "/home/ubuntu/deployments/2025-04-10-1013/rtg-monorepo/src/applications/rolfsbuss-web/pages/index.vue?macro=true";
import { default as grouptravelCcx1NwDjtRMeta } from "/home/ubuntu/deployments/2025-04-10-1013/rtg-monorepo/src/applications/rolfsbuss-web/pages/info/grouptravel.vue?macro=true";
import { default as indexUoCf6Gi5oWMeta } from "/home/ubuntu/deployments/2025-04-10-1013/rtg-monorepo/src/applications/rolfsbuss-web/pages/info/index.vue?macro=true";
import { default as lastminuteCaV1loArAQMeta } from "/home/ubuntu/deployments/2025-04-10-1013/rtg-monorepo/src/applications/rolfsbuss-web/pages/lastminute.vue?macro=true";
import { default as mapYHqZgLL8RuMeta } from "/home/ubuntu/deployments/2025-04-10-1013/rtg-monorepo/src/applications/rolfsbuss-web/pages/map.vue?macro=true";
import { default as dinbokningr00uzBZYPtMeta } from "/home/ubuntu/deployments/2025-04-10-1013/rtg-monorepo/src/layers/web/pages/mytrip/dinbokning.vue?macro=true";
import { default as newsletterNZT7D4HQgGMeta } from "/home/ubuntu/deployments/2025-04-10-1013/rtg-monorepo/src/layers/web/pages/newsletter.vue?macro=true";
import { default as offlinesC583jxtOVMeta } from "/home/ubuntu/deployments/2025-04-10-1013/rtg-monorepo/src/layers/web/pages/offline.vue?macro=true";
import { default as failed8mhsUYT0nHMeta } from "/home/ubuntu/deployments/2025-04-10-1013/rtg-monorepo/src/layers/web/pages/payment/failed.vue?macro=true";
import { default as index4u56QdOZswMeta } from "/home/ubuntu/deployments/2025-04-10-1013/rtg-monorepo/src/layers/web/pages/review/index.vue?macro=true";
import { default as search0nXVG9BBXaMeta } from "/home/ubuntu/deployments/2025-04-10-1013/rtg-monorepo/src/layers/web/pages/search.vue?macro=true";
import { default as indexcasFRiHhfHMeta } from "/home/ubuntu/deployments/2025-04-10-1013/rtg-monorepo/src/layers/web/pages/tourleader/index.vue?macro=true";
import { default as verification_45donei67qiAdmQEMeta } from "/home/ubuntu/deployments/2025-04-10-1013/rtg-monorepo/src/layers/web/pages/verification-done.vue?macro=true";
export default [
  {
    name: "tourleader-id",
    path: "/reseledare/:id()",
    component: () => import("/home/ubuntu/deployments/2025-04-10-1013/rtg-monorepo/src/layers/web/pages/tourleader/[id]/[slug].vue")
  },
  {
    name: "titinerary",
    path: "/dagprogram",
    component: () => import("/home/ubuntu/deployments/2025-04-10-1013/rtg-monorepo/src/layers/web/pages/titinerary/[slug].vue")
  },
  {
    name: "shipinfo",
    path: "/fartyg",
    component: () => import("/home/ubuntu/deployments/2025-04-10-1013/rtg-monorepo/src/layers/web/pages/shipinfo/[name].vue")
  },
  {
    name: "satisfactionstatus-triptypeid",
    path: "/nojdhet-status/:triptypeid()",
    component: () => import("/home/ubuntu/deployments/2025-04-10-1013/rtg-monorepo/src/applications/rolfsbuss-web/pages/satisfactionstatus/[triptypeid]/[paxid].vue")
  },
  {
    name: "payment",
    path: "/payment",
    component: () => import("/home/ubuntu/deployments/2025-04-10-1013/rtg-monorepo/src/layers/web/pages/payment/[id].vue")
  },
  {
    name: "omdome",
    path: "/omdome",
    component: () => import("/home/ubuntu/deployments/2025-04-10-1013/rtg-monorepo/src/layers/web/pages/omdome/[id].vue")
  },
  {
    name: "mytrip",
    path: "/dinresa",
    component: () => import("/home/ubuntu/deployments/2025-04-10-1013/rtg-monorepo/src/layers/web/pages/mytrip/[tab].vue")
  },
  {
    name: "info-lectures",
    path: "/info/forelasningar",
    component: () => import("/home/ubuntu/deployments/2025-04-10-1013/rtg-monorepo/src/applications/rolfsbuss-web/pages/info/lectures/[tab].vue")
  },
  {
    name: "info-connections",
    path: "/info/anslutning",
    component: () => import("/home/ubuntu/deployments/2025-04-10-1013/rtg-monorepo/src/applications/rolfsbuss-web/pages/info/connections/[tab].vue")
  },
  {
    name: "info-about",
    path: "/info/om",
    component: () => import("/home/ubuntu/deployments/2025-04-10-1013/rtg-monorepo/src/applications/rolfsbuss-web/pages/info/about/[tab].vue")
  },
  {
    name: "info",
    path: "/info",
    component: () => import("/home/ubuntu/deployments/2025-04-10-1013/rtg-monorepo/src/layers/web/pages/info/[slug].vue")
  },
  {
    name: "fn",
    path: "/fn",
    component: () => import("/home/ubuntu/deployments/2025-04-10-1013/rtg-monorepo/src/layers/web/pages/fn/[id].vue")
  },
  {
    name: "destination-slug",
    path: "/destination/:slug()",
    component: () => import("/home/ubuntu/deployments/2025-04-10-1013/rtg-monorepo/src/applications/rolfsbuss-web/pages/destination/[slug]/[tab].vue")
  },
  {
    name: "competition",
    path: "/tavling",
    component: () => import("/home/ubuntu/deployments/2025-04-10-1013/rtg-monorepo/src/layers/web/pages/competition/[slug].vue")
  },
  {
    name: "category",
    path: "/kategori",
    component: () => import("/home/ubuntu/deployments/2025-04-10-1013/rtg-monorepo/src/applications/rolfsbuss-web/pages/category/[slug].vue")
  },
  {
    name: "bookingfaq",
    path: "/boknings-faq",
    component: () => import("/home/ubuntu/deployments/2025-04-10-1013/rtg-monorepo/src/layers/web/pages/bookingfaq/[ocr].vue")
  },
  {
    name: "booking-finish",
    path: "/booking-finish",
    component: () => import("/home/ubuntu/deployments/2025-04-10-1013/rtg-monorepo/src/layers/web/pages/booking-finish/[ocr].vue")
  },
  {
    name: "booking-confirmation",
    path: "/booking-confirmation",
    component: () => import("/home/ubuntu/deployments/2025-04-10-1013/rtg-monorepo/src/layers/web/pages/booking-confirmation/[id].vue")
  },
  {
    name: "bookflight",
    path: "/boka-flyg",
    component: () => import("/home/ubuntu/deployments/2025-04-10-1013/rtg-monorepo/src/applications/rolfsbuss-web/pages/bookflight/[tripid].vue")
  },
  {
    name: "book-tripid",
    path: "/boka/:tripid()",
    component: () => import("/home/ubuntu/deployments/2025-04-10-1013/rtg-monorepo/src/layers/web/pages/book/[tripid]/[step].vue")
  },
  {
    name: "auth",
    path: "/medlem",
    component: () => import("/home/ubuntu/deployments/2025-04-10-1013/rtg-monorepo/src/applications/rolfsbuss-web/pages/auth/[slug].vue")
  },
  {
    name: "app-l",
    path: "/app/l",
    component: () => import("/home/ubuntu/deployments/2025-04-10-1013/rtg-monorepo/src/layers/web/pages/app/l/[jwt].vue")
  },
  {
    name: "triptype-slug",
    path: "/:triptype()/:slug()",
    component: () => import("/home/ubuntu/deployments/2025-04-10-1013/rtg-monorepo/src/layers/web/pages/[triptype]/[slug]/[tab].vue")
  },
  {
    name: "triptype-slug-tab",
    path: "/:triptype()/:slug()/:tab()",
    meta: _91tab_93tbRlCgrLrLMeta || {},
    component: () => import("/home/ubuntu/deployments/2025-04-10-1013/rtg-monorepo/src/layers/web/pages/[triptype]/[slug]/[tab].vue")
  },
  {
    name: "app",
    path: "/app",
    component: () => import("/home/ubuntu/deployments/2025-04-10-1013/rtg-monorepo/src/layers/web/pages/app/index.vue")
  },
  {
    name: "app-l-jwt",
    path: "/app/l/:jwt()",
    component: () => import("/home/ubuntu/deployments/2025-04-10-1013/rtg-monorepo/src/layers/web/pages/app/l/[jwt].vue")
  },
  {
    name: "auth-slug",
    path: "/medlem/:slug()",
    component: () => import("/home/ubuntu/deployments/2025-04-10-1013/rtg-monorepo/src/applications/rolfsbuss-web/pages/auth/[slug].vue")
  },
  {
    name: "auth-callback",
    path: "/medlem/callback",
    component: () => import("/home/ubuntu/deployments/2025-04-10-1013/rtg-monorepo/src/applications/rolfsbuss-web/pages/auth/callback.vue")
  },
  {
    name: "blackweek",
    path: "/blackweek",
    component: () => import("/home/ubuntu/deployments/2025-04-10-1013/rtg-monorepo/src/applications/rolfsbuss-web/pages/blackweek.vue")
  },
  {
    name: "blog-slug",
    path: "/blogg/:slug()",
    component: () => import("/home/ubuntu/deployments/2025-04-10-1013/rtg-monorepo/src/applications/rolfsbuss-web/pages/blog/[slug].vue")
  },
  {
    name: "blog",
    path: "/blogg",
    component: () => import("/home/ubuntu/deployments/2025-04-10-1013/rtg-monorepo/src/applications/rolfsbuss-web/pages/blog/index.vue")
  },
  {
    name: "book-tripid-step",
    path: "/boka/:tripid()/:step()",
    component: () => import("/home/ubuntu/deployments/2025-04-10-1013/rtg-monorepo/src/layers/web/pages/book/[tripid]/[step].vue")
  },
  {
    name: "bookcall",
    path: "/boka-samtal",
    component: () => import("/home/ubuntu/deployments/2025-04-10-1013/rtg-monorepo/src/applications/rolfsbuss-web/pages/bookcall.vue")
  },
  {
    name: "bookflight-tripid",
    path: "/boka-flyg/:tripid()",
    component: () => import("/home/ubuntu/deployments/2025-04-10-1013/rtg-monorepo/src/applications/rolfsbuss-web/pages/bookflight/[tripid].vue")
  },
  {
    name: "booking-confirmation-id",
    path: "/booking-confirmation/:id()",
    component: () => import("/home/ubuntu/deployments/2025-04-10-1013/rtg-monorepo/src/layers/web/pages/booking-confirmation/[id].vue")
  },
  {
    name: "booking-finish-ocr",
    path: "/booking-finish/:ocr()",
    component: () => import("/home/ubuntu/deployments/2025-04-10-1013/rtg-monorepo/src/layers/web/pages/booking-finish/[ocr].vue")
  },
  {
    name: "bookingfaq-ocr",
    path: "/boknings-faq/:ocr()",
    component: () => import("/home/ubuntu/deployments/2025-04-10-1013/rtg-monorepo/src/layers/web/pages/bookingfaq/[ocr].vue")
  },
  {
    name: "byebyeie",
    path: "/byebyeie",
    component: () => import("/home/ubuntu/deployments/2025-04-10-1013/rtg-monorepo/src/layers/web/pages/byebyeie.vue")
  },
  {
    name: "calendar",
    path: "/kalender",
    meta: indexwgoBYZa0r0Meta || {},
    component: () => import("/home/ubuntu/deployments/2025-04-10-1013/rtg-monorepo/src/applications/rolfsbuss-web/pages/calendar/index.vue")
  },
  {
    name: "calendar-lastminute",
    path: "/kalender/sista-minuten",
    component: () => import("/home/ubuntu/deployments/2025-04-10-1013/rtg-monorepo/src/applications/rolfsbuss-web/pages/calendar/lastminute.vue")
  },
  {
    name: "campaign-name",
    path: "/kampanj/:name()",
    component: () => import("/home/ubuntu/deployments/2025-04-10-1013/rtg-monorepo/src/applications/rolfsbuss-web/pages/campaign/[name].vue")
  },
  {
    name: "campaign",
    path: "/kampanj",
    component: () => import("/home/ubuntu/deployments/2025-04-10-1013/rtg-monorepo/src/applications/rolfsbuss-web/pages/campaign/index.vue")
  },
  {
    name: "catalogue",
    path: "/katalog",
    meta: catalogueO8akbl58agMeta || {},
    component: () => import("/home/ubuntu/deployments/2025-04-10-1013/rtg-monorepo/src/applications/rolfsbuss-web/pages/catalogue.vue")
  },
  {
    name: "category-slug",
    path: "/kategori/:slug()",
    meta: _91slug_93MAUuOgOkYzMeta || {},
    component: () => import("/home/ubuntu/deployments/2025-04-10-1013/rtg-monorepo/src/applications/rolfsbuss-web/pages/category/[slug].vue")
  },
  {
    name: "competition-slug",
    path: "/tavling/:slug()",
    component: () => import("/home/ubuntu/deployments/2025-04-10-1013/rtg-monorepo/src/layers/web/pages/competition/[slug].vue")
  },
  {
    name: "complaint",
    path: "/reklamation",
    meta: complaintZ0M5siPs5cMeta || {},
    component: () => import("/home/ubuntu/deployments/2025-04-10-1013/rtg-monorepo/src/layers/web/pages/complaint.vue")
  },
  {
    name: "destination-slug-tab",
    path: "/destination/:slug()/:tab()",
    meta: _91tab_93o7CuulPASbMeta || {},
    component: () => import("/home/ubuntu/deployments/2025-04-10-1013/rtg-monorepo/src/applications/rolfsbuss-web/pages/destination/[slug]/[tab].vue")
  },
  {
    name: "dinbokning-aktuell",
    path: "/dinbokning-aktuell",
    component: () => import("/home/ubuntu/deployments/2025-04-10-1013/rtg-monorepo/src/layers/web/pages/dinbokning-aktuell.vue")
  },
  {
    name: "event-slug",
    path: "/event/:slug()",
    component: () => import("/home/ubuntu/deployments/2025-04-10-1013/rtg-monorepo/src/applications/rolfsbuss-web/pages/event/[slug].vue")
  },
  {
    name: "event",
    path: "/event",
    component: () => import("/home/ubuntu/deployments/2025-04-10-1013/rtg-monorepo/src/applications/rolfsbuss-web/pages/event/index.vue")
  },
  {
    name: "faq",
    path: "/faq",
    component: () => import("/home/ubuntu/deployments/2025-04-10-1013/rtg-monorepo/src/layers/web/pages/faq.vue")
  },
  {
    name: "fn-id",
    path: "/fn/:id()",
    component: () => import("/home/ubuntu/deployments/2025-04-10-1013/rtg-monorepo/src/layers/web/pages/fn/[id].vue")
  },
  {
    name: "giftcard-id",
    path: "/presentkort/:id()",
    component: () => import("/home/ubuntu/deployments/2025-04-10-1013/rtg-monorepo/src/layers/web/pages/giftcard/[id].vue")
  },
  {
    name: "giftcard",
    path: "/presentkort",
    component: () => import("/home/ubuntu/deployments/2025-04-10-1013/rtg-monorepo/src/layers/web/pages/giftcard/index.vue")
  },
  {
    name: "hotels-name",
    path: "/hotell/:name()",
    meta: _91name_93ZxO56fZlAQMeta || {},
    component: () => import("/home/ubuntu/deployments/2025-04-10-1013/rtg-monorepo/src/layers/web/pages/hotels/[name].vue")
  },
  {
    name: "hotels",
    path: "/hotell",
    component: () => import("/home/ubuntu/deployments/2025-04-10-1013/rtg-monorepo/src/layers/web/pages/hotels/index.vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("/home/ubuntu/deployments/2025-04-10-1013/rtg-monorepo/src/applications/rolfsbuss-web/pages/index.vue")
  },
  {
    name: "info-slug",
    path: "/info/:slug()",
    meta: _91slug_93n4njValg2HMeta || {},
    component: () => import("/home/ubuntu/deployments/2025-04-10-1013/rtg-monorepo/src/layers/web/pages/info/[slug].vue")
  },
  {
    name: "info-about-tab",
    path: "/info/om/:tab()",
    component: () => import("/home/ubuntu/deployments/2025-04-10-1013/rtg-monorepo/src/applications/rolfsbuss-web/pages/info/about/[tab].vue")
  },
  {
    name: "info-connections-tab",
    path: "/info/anslutning/:tab()",
    meta: _91tab_93FrQYuzzQJsMeta || {},
    component: () => import("/home/ubuntu/deployments/2025-04-10-1013/rtg-monorepo/src/applications/rolfsbuss-web/pages/info/connections/[tab].vue")
  },
  {
    name: "info-grouptravel",
    path: "/info/gruppresor",
    component: () => import("/home/ubuntu/deployments/2025-04-10-1013/rtg-monorepo/src/applications/rolfsbuss-web/pages/info/grouptravel.vue")
  },
  {
    name: "info",
    path: "/info",
    component: () => import("/home/ubuntu/deployments/2025-04-10-1013/rtg-monorepo/src/applications/rolfsbuss-web/pages/info/index.vue")
  },
  {
    name: "info-lectures-tab",
    path: "/info/forelasningar/:tab()",
    component: () => import("/home/ubuntu/deployments/2025-04-10-1013/rtg-monorepo/src/applications/rolfsbuss-web/pages/info/lectures/[tab].vue")
  },
  {
    name: "lastminute",
    path: "/sista-minuten-erbjudanden",
    component: () => import("/home/ubuntu/deployments/2025-04-10-1013/rtg-monorepo/src/applications/rolfsbuss-web/pages/lastminute.vue")
  },
  {
    name: "map",
    path: "/karta",
    component: () => import("/home/ubuntu/deployments/2025-04-10-1013/rtg-monorepo/src/applications/rolfsbuss-web/pages/map.vue")
  },
  {
    name: "mytrip-tab",
    path: "/dinresa/:tab()",
    meta: _91tab_93LPK9bBaKvAMeta || {},
    component: () => import("/home/ubuntu/deployments/2025-04-10-1013/rtg-monorepo/src/layers/web/pages/mytrip/[tab].vue")
  },
  {
    name: "mytrip-dinbokning",
    path: "/mytrip/dinbokning",
    component: () => import("/home/ubuntu/deployments/2025-04-10-1013/rtg-monorepo/src/layers/web/pages/mytrip/dinbokning.vue")
  },
  {
    name: "newsletter",
    path: "/nyhetsbrev",
    component: () => import("/home/ubuntu/deployments/2025-04-10-1013/rtg-monorepo/src/layers/web/pages/newsletter.vue")
  },
  {
    name: "offline",
    path: "/offline",
    component: () => import("/home/ubuntu/deployments/2025-04-10-1013/rtg-monorepo/src/layers/web/pages/offline.vue")
  },
  {
    name: "omdome-id",
    path: "/omdome/:id()",
    meta: _91id_93jzCoUS6ExyMeta || {},
    component: () => import("/home/ubuntu/deployments/2025-04-10-1013/rtg-monorepo/src/layers/web/pages/omdome/[id].vue")
  },
  {
    name: "payment-id",
    path: "/payment/:id()",
    component: () => import("/home/ubuntu/deployments/2025-04-10-1013/rtg-monorepo/src/layers/web/pages/payment/[id].vue")
  },
  {
    name: "payment-failed",
    path: "/payment/failed",
    component: () => import("/home/ubuntu/deployments/2025-04-10-1013/rtg-monorepo/src/layers/web/pages/payment/failed.vue")
  },
  {
    name: "review",
    path: "/utvardera-resa",
    component: () => import("/home/ubuntu/deployments/2025-04-10-1013/rtg-monorepo/src/layers/web/pages/review/index.vue")
  },
  {
    name: "satisfactionstatus-triptypeid-paxid",
    path: "/nojdhet-status/:triptypeid()/:paxid()",
    component: () => import("/home/ubuntu/deployments/2025-04-10-1013/rtg-monorepo/src/applications/rolfsbuss-web/pages/satisfactionstatus/[triptypeid]/[paxid].vue")
  },
  {
    name: "search",
    path: "/sok",
    component: () => import("/home/ubuntu/deployments/2025-04-10-1013/rtg-monorepo/src/layers/web/pages/search.vue")
  },
  {
    name: "shipinfo-name",
    path: "/fartyg/:name()",
    meta: _91name_93DKqlzT6carMeta || {},
    component: () => import("/home/ubuntu/deployments/2025-04-10-1013/rtg-monorepo/src/layers/web/pages/shipinfo/[name].vue")
  },
  {
    name: "titinerary-slug",
    path: "/dagprogram/:slug()",
    component: () => import("/home/ubuntu/deployments/2025-04-10-1013/rtg-monorepo/src/layers/web/pages/titinerary/[slug].vue")
  },
  {
    name: "tourleader-id-slug",
    path: "/reseledare/:id()/:slug()",
    component: () => import("/home/ubuntu/deployments/2025-04-10-1013/rtg-monorepo/src/layers/web/pages/tourleader/[id]/[slug].vue")
  },
  {
    name: "tourleader",
    path: "/reseledare",
    component: () => import("/home/ubuntu/deployments/2025-04-10-1013/rtg-monorepo/src/layers/web/pages/tourleader/index.vue")
  },
  {
    name: "verification-done",
    path: "/verification-done",
    meta: verification_45donei67qiAdmQEMeta || {},
    component: () => import("/home/ubuntu/deployments/2025-04-10-1013/rtg-monorepo/src/layers/web/pages/verification-done.vue")
  }
]